


























































































import Vue from "vue";
import Component from "vue-class-component";
import { Tabs, Tab, Cell, Tag, List, Empty, Collapse, CollapseItem, Button, Search, Toast, Dialog, ImagePreview } from "vant";
import Moment from "moment";
Moment.locale("zh-cn");

enum Active {
  Reason,
  Site,
  Device
}
@Component({
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [List.name]: List,
    [Empty.name]: Empty,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [Search.name]: Search
  }
})
export default class DeviceRepair extends Vue {
  active = 0;
  Active = Active;
  collapse = -1;

  searchKeyword = "";
  keyword = "";
  siteId = 0;
  siteName = "";
  reason = 0;
  reasonMsg = "";

  reasonList = [] as { reason: number; reasonMsg: string; count: 0 }[];
  siteList = [] as { siteId: number; siteName: string; count: 0 }[];
  deviceList = [] as { cabinetId: number; qrCode: string; count: 0; list: [] }[];
  repairList = [];

  created() {
    this.loadData();
  }

  onSearch() {
    this.keyword = this.searchKeyword;
    this.active = Active.Device;
    this.loadData();
  }
  clearKeyword() {
    this.keyword = "";
    this.loadData();
  }
  clearSite() {
    this.siteId = 0;
    this.loadData();
  }
  clearReason() {
    this.reason = 0;
    this.loadData();
  }

  formatTime(time: number) {
    return Moment(time).format("lll");
  }
  loadData() {
    if (this.active == Active.Reason) {
      this.getReasonData();
    } else if (this.active == Active.Site) {
      this.getSiteData();
    } else if (this.active == Active.Device) {
      this.getDeviceData();
    }
  }
  updateStatus(repair: { id: number }, index: number, status: number, item: { count: number; list: [] }, msg: string) {
    Dialog.confirm({
      message: "您确定要标记为" + msg + "吗?"
    }).then(() => {
      this.$axios.post("/api/manage/cabinet/updateRepair", { repairId: repair.id, status: status }).then(() => {
        Toast.success("操作成功");
        item.count = item.count - 1;
        item.list.splice(index, 1);
      });
    });
  }
  contact(phone: string) {
    location.href = "tel://" + phone;
  }
  viewImages(imageIds: string) {
    if (!imageIds) {
      Toast.fail("无图片可供查看");
      return;
    }
    ImagePreview(imageIds.split(",").map(v => "https://resource.letfungo.com/" + v));
  }
  toOrder(id: number, type: number) {
    if (type == 0) {
      this.$toMiniAppWeb("/web/manager/v/battery-order/detail/" + id);
    } else if (type == 4) {
      this.$toMiniAppWeb("/web/manager/battery_replace_order_detail?exchange_order_id=" + id);
    } else {
      Toast.fail("未知的订单类型");
    }
  }
  toDevice(repair: { qrCode: string; cabinetRoomId: number }) {
    if (repair.cabinetRoomId > 0) {
      this.$toMiniAppWeb("/web/manager/manager_switch_cabinet_information?roomQrCode=" + repair.qrCode);
    } else {
      this.$toMiniAppWeb("/web/manager/manager_switch_cabinet_information?qrCode=" + repair.qrCode);
    }
  }
  getReasonData() {
    return this.$axios.post("/api/manage/cabinet/getRepairListGroupByReason").then(e => {
      const data = e.data.data;
      this.reasonList = data;
    });
  }
  getSiteData() {
    return this.$axios.post("/api/manage/cabinet/getRepairListGroupBySite", { reason: this.reason }).then(e => {
      const data = e.data.data;
      this.siteList = data;
    });
  }
  getDeviceData() {
    const data = {
      reason: this.reason,
      siteId: this.siteId,
      keyword: this.keyword
    };
    if (!data.reason && !data.siteId && !data.keyword) {
      this.deviceList = [];
      return;
    }
    return this.$axios.post("/api/manage/cabinet/getRepairListGroupByCabinet", data).then(e => {
      const data = e.data.data;
      this.deviceList = data;
    });
  }
  getCabinetData(cabinetId: number) {
    return this.$axios.post("/api/manage/cabinet/getRepairListByCabinetId", { cabinetId: cabinetId }).then(e => {
      const data = e.data.data;
      this.repairList = data;
    });
  }
  onClickReason(item: { reason: number; reasonMsg: string }) {
    this.reason = item.reason;
    this.reasonMsg = item.reasonMsg;
    this.active = Active.Site;
    this.getSiteData();
  }
  onClickSite(item: { siteId: number; siteName: string }) {
    this.siteId = item.siteId;
    this.siteName = item.siteName;
    this.active = Active.Device;
    this.getDeviceData();
  }
}
